.button {
    border-radius: 10px;
    height: 44px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 0.95rem;
    padding: 6px 12px;

    &:hover {
        cursor: pointer;
    }
}

.primary {
    background-color: var(--primary-color);
    border: 0.8px solid #000;
    color: black;

    &:hover {
        border: 1px solid #000;
    }

    [data-mantine-color-scheme="dark"] & {
        background-color: var(--primary-color);
        border: none;

        &:hover {
            background-color: var(--primary-color-shade);
        }
    }
}

.secondary {
    border: 0.8px solid #000;
    background-color: var(--secondary-color);
    color: white;

    &:hover {
        background-color: var(--secondary-color-shade);
    }

    [data-mantine-color-scheme="dark"] & {
        border: 0.8px solid var(--off-white);
    }
}

.secondaryOutline {
    background-color: white;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    min-width: fit-content;

    &:hover {
        background-color: var(--off-white);
    }

    [data-mantine-color-scheme="dark"] & {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        background-color: #202020;

        &:hover {
            border: 1.5px solid var(--primary-color);
        }
    }
}

.mobileBooking {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    width: 140px;
    font-size: 1rem;
    color: white;

    &:hover {
        background-color: var(--secondary-color-shade);
    }

    [data-mantine-color-scheme="dark"] & {
        background-color: var(--primary-color);
        border: none;
        color: black;

        &:hover {
            background-color: var(--primary-color-shade);
            color: black;
        }
    }
}

.outlineAlert {
    border: 1px solid red;
    color: red;
    background-color: white;

    &:hover {
        background-color: var(--off-white);
    }

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;

        &:hover {
            background-color: var(--off-white);
        }
    }
}

.clearbuttonroot {
    background-color: transparent;
    border: none;
    min-width: fit-content;
    color: black;

    &:hover {
        background-color: var(--primary-color);
        color: black;
    }

    [data-mantine-color-scheme="dark"] & {
        background-color: transparent;
        color: white;

        &:hover {
            border-bottom: 1px solid var(--primary-color);
            border-radius: 0px;
            background-color: transparent;
            color: white;
        }
    }
}

.clearbuttonlabel {
    color: black;
    font-size: 1rem;

    [data-mantine-color-scheme="dark"] & {
        color: white;

        &:hover {
            color: white;
        }
    }
}

.subtle {
    color: black;
    background-color: transparent;
    border: none;
    min-width: fit-content;
    border-radius: 0px;

    &:hover {
        border-bottom: 1.5px solid var(--secondary-color);
        background-color: transparent;
    }

    [data-mantine-color-scheme="dark"] & {
        color: white;

        &:hover {
            border-bottom: 1px solid var(--primary-color);
            background-color: transparent;
            color: white;
        }
    }
}

.clearOutline {
    border: 1px solid #333;
    color: #333;
    border-radius: 10px;
    background-color: white;

    &:hover {
        background-color: var(--off-white-shade);
    }

    [data-mantine-color-scheme="dark"] & {
        border: 1px solid var(--off-white);
        color: var(--off-white);
        background-color: #202020;

        &:hover {
            border: 1.5px solid var(--off-white);
        }
    }
}

.iconbuttonroot {
    border-radius: 5px;
    border: 0.8px solid black;
    height: 36px;
}

.iconbuttonlabel {
    font-size: 0.8 rem;
}

.filterbuttonroot {
    border: 1px solid #333;
    color: black;
    border-radius: 10px;


    &:hover {
        color: black;
        background-color: var(--off-white);
    }

    [data-mantine-color-scheme="dark"] & {
        color: var(--off-white);
        border: 1px solid white;

        &:hover {
            background-color: transparent;
            border: 1.5px solid white;
        }
    }
}

.actionbuttonroot {
    color: var(--secondary-color);
    border-radius: 10px;
    margin: 0px;

    &:hover {
        cursor: pointer;
        background-color: var(--secondary-color-shade);
        color: var(--off-white);
    }

    [data-mantine-color-scheme="dark"] & {
        color: var(--off-white);

        &:hover {
            background-color: var(--secondary-color);

        }
    }
}

.alertbuttonroot {
    color: red;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 0, 0, 0.15);
        color: red;
    }

    [data-mantine-color-scheme="dark"] & {
        color: red;

        &:hover {
            background-color: rgba(255, 0, 0, 0.15);
            color: red;
        }
    }
}

.countIconRoot {
    color: white;
    background-color: var(--secondary-color);
    border-radius: 30px;
    padding: 2px;

    &:hover {
        cursor: pointer;
        background-color: var(--secondary-color-shade);
    }
}

.favroot {
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
}

.badgeroot {
    padding: 6px;
    border-radius: 20px;
    margin: 5px;
    border: 0.5px solid black;
    background-color: var(--primary-color);
}

.badgelabel {
    color: #000;
    font-size: 11px;
    font-weight: 400;
}

/* accountNav */
.navcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flext-start;
    height: auto;
    border: 0.5px solid var(--off-white);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    min-height: 350px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        box-shadow: none;
        border: none;
    }
}

.navbar {
    display: flex;
    flex-direction: column;
    background-color: var(--off-white);
    height: auto;
    border-radius: 10px;
    width: fit-content;
    padding: 20px 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
        border: 1px solid var(--off-white);
    }
}

.navbuttonroot {
    display: flex;
    flex-direction: row;
    width: 240px;
    height: 36px;
    margin: 15px 10px;

    &:hover {
        cursor: pointer;
        background-color: #a08ed3;
    }

    [data-mantine-color-scheme="dark"] & {
        color: white;

        &:hover {
            background-color: gray;
        }
    }
}

.navbuttonlabel {
    font-weight: 400;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;

    [data-mantine-color-scheme="dark"] & {
        color: white;
    }
}

.navcontent {
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
    min-width: 400px;
    max-width: fit-content;

    @media (max-width: 768px) {
        height: fit-content;
        padding: 10px;
        align-items: center;
        justify-content: center;
        min-width: 280px;
    }
}

/* elements */
.switchlabel {
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.filtergroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 95%;
    height: 130px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
    }

    &:hover {
        cursor: pointer;
    }
}

.filtercarousel {
    width: 80%;
    margin: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 10px;
    }
}

.carouselContainer {
    margin-left: 6px;
    margin-right: 6px;
}

.textlabel {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 6px;
    font-family: "Montserrat", sans-serif;
    min-width: 250px;

    @media screen and (max-width: 460px) {
        font-size: 0.875rem;
    }
}

.textinput {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;

    @media screen and (max-width: 460px) {
        font-size: 0.7rem;
    }
}

.mspill {
    background-color: var(--primary-color);
    font-family: "Montserrat", sans-serif;
    font-size: 0.8rem;
}

.optionlabel {
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
}

.segmentroot {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-x: hidden;
    border: 0.5px solid #ccc;
    background-color: white;
    border-radius: 30px;
    padding: 6px 10px;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);

    [data-mantine-color-scheme="dark"] & {
        background-color: #202020;
    }
}

.segmentlabel {
    color: #000;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;

    [data-mantine-color-scheme="dark"] & {
        color: var(--off-white);
    }
}

.segmentindicator {
    background-color: var(--primary-color);
    border-radius: 30px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

    [data-mantine-color-scheme="dark"] & {
        background-color: var(--secondary-color);
    }
}

.smallcard {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 90px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    }
}

.img {
    width: 40px;
    height: 40px;
}

.cardlabel {
    font-size: 0.9rem;
    width: fit-content;
}

/* hero */
.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 15px;
    margin-top: 30px;
    height: 320px;
    width: 100%;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 485px) {
        height: 170px;
    }
}

.heroGroup {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    z-index: 1;
    margin: 0px;
    padding: 30px 40px;

    @media screen and (max-width: 485px) {
        padding: 15px 20px;
    }
}

.heroStack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    gap: 10px;
    height: auto;

    @media screen and (max-width: 460px) {
        padding: 6px 10px;
        gap: 6px;
        justify-content: flex-start;
    }
}

.heroTitle {
    color: white;
    font-size: 4.8rem;
    font-weight: 800;
    line-height: 1.1;
    text-transform: capitalize;
    margin-top: 30px;
    margin-bottom: 15px;
    letter-spacing: 1px;

    @media screen and (max-width: 768px) {
        font-size: 3.5rem;
        margin-top: 10px;
    }

    @media screen and (max-width: 486px) {
        font-size: 2.1rem;
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

.herosubheading {
    color: white;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.3;
    margin: 0px;

    @media screen and (max-width: 768px) {
        font-size: 1.6rem;
    }

    @media screen and (max-width: 486px) {
        font-size: 1.1rem;
    }
}

/* skeleton */

.skeletonheroroot {
    width: 100%;
    margin-top: 40px;

    @media screen and (max-width: 460px) {
        height: 170px;
    }
}

.skeletonpageroot {
    margin-top: 12px;
    margin-left: 10px;
}

.skeletonpageholderroot {
    background-color: white;
    margin-top: 1500px;
}

.exppageskeleton {
    margin: 30px 50px;
}