.searchbar {
    max-width: 400px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    height: 36px;
    min-width: 300px;

    @media screen and (max-width: 440px) {
        min-width: 280px;
        max-width: 300px;
    }
}

.searchmodal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    min-width: 300px;
    padding: 15px;
    margin: 6px;
    height: fit-content;

    @media screen and (max-width: 400px) {
        width: 300px;
        height: auto;
    }
}

.featuretitle {
    font-size: 1.2rem;
    margin: 10px;
    text-transform: capitalize;
    text-align: center;
}

/* experienceBar */
.experiencecard {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 75px;

    padding: 10px;

    &:hover {
        cursor: pointer;
        border-bottom: 1px solid var(--secondary-color);
    }
}

.experienceicon {
    margin: 5px;
    width: 30px;
    height: 30px;
}

.label {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 5px;
    line-height: 1;
    text-transform: capitalize;
}

.filterModal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
}

.filterbar {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px 20px;
    align-items: center;

    @media screen and (max-width: 460px) {
        flex-direction: column;
        gap: 10px;
    }
}

.experiencebar {
    width: 80%;
    margin-right: 10px;

    @media screen and (max-width: 460px) {
        width: 100%;
        margin: 5px;
    }
}

.filterbuttongroup {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

/* search */
.searchgroup {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media screen and (max-width: 485px) {
        flex-direction: column;
        gap: 5px;
    }
}